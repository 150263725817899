import React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/layout/layout"
import SEO from "../../components/layout/seo"
import Isaac from "../../components/pages/isaac/isaac"
import AccessWrapper from '../../components/pages/AccessWrapper'

const IsaacPage = ({ data }) => {

  const story = data.storyblokEntry
  const content = JSON.parse(story.content)
  const media = content.isaacFullVideo

  return(
    <Layout>
      <SEO title="Home" />
      <AccessWrapper introString='Isaac Chong Wai' content={content.artists[12]} id={12}>
        <Isaac media={media} content={content}/>
      </AccessWrapper>
    </Layout>
  )
}

export default IsaacPage

export const query = graphql`
  query {
    storyblokEntry(slug: { eq: "artists" }) {
      content
    }
  }
`